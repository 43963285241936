import React, {useEffect, useState} from "react";
import {Card, TextInput, Button, Divider} from "hydrogen";
import { toast } from "react-toastify";
import useUI from "contexts/ui";
import FilePicker from "components/FilePicker";
import axios from "axios";

const Profile = () => {
  const [ui, setUI] = useUI();
  const [errors, setErrors] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [loading, setLoading] = useState(false);

  const [signature, setSignature] = useState(null);
  const [tampon, setTampon] = useState(null);

  useEffect(() => {
    setSignature(ui.user.signature);
    setTampon(ui.user.tampon);
  }, [ui.user]);

  const _onSubmit = async () => {
    setErrors("");
    setLoading(true);
    if (!password) {
      setErrors("Le mot de passe est requis");
      setLoading(false);
      return;
    }
    if (!confirm) {
      setErrors("La confirmation du mot de passe est requis");
      setLoading(false);
      return;
    }
    if (confirm !== password) {
      setErrors("Les mots de passe ne correspondent pas");
      setLoading(false);
      return;
    }
    try {
      toast.error("Cette fonctionnalité n'est pas encore disponible. Veuillez contacter l'administrateur du site pour plus d'informations.")
      //await firebase.auth().currentUser.updatePassword(password);
      //toast.success("Votre mot de passe a été changé avec success.");
      setPassword("");
      setConfirm("");
    } catch (e) {
      toast.error(e.message);
    }
    setLoading(false);
  }

  const _onSubmitSig = async () => {
    try {
      await axios.put(`/api/users/${ui.user.id}`, {
        signature,
        tampon
      })
      setUI({...ui, user: {...ui.user, signature, tampon}});
      toast.success("Votre signature a été changée avec success.")
    } catch (e) {
      toast.error(e.message);
      console.error(e);
    }
  }

  return (
    <>
      <Card>
        <Divider title="Securité" />
        <div className="flex flex-wrap" style={{columnGap: 15}}>
          <TextInput 
            type="password"
            className="flex-1"
            label="Mot de passe"
            placeholder="·········"
            value={password}
            onChange={setPassword}
            invalid={errors.length > 0}
          />
          <TextInput
            type="password"
            className="flex-1"
            label="Confirmer"
            placeholder="·········"
            value={confirm}
            onChange={setConfirm}
            invalid={errors.length > 0}
          />
        </div>

        {errors ? <p className="mb-2 text-red-500 font-semibold text-center">{errors}</p>: <></>}
        <div className="flex items-center justify-center">
          <Button color="primary" onClick={_onSubmit} loading={loading}>Valider</Button>
        </div>
      </Card>
      {ui.user.role.name === "medecin" ? 
      <Card className="mt-2">
        <Divider title="Signature (ordonnance)" />
        <FilePicker style={{flex: 1}} label="Signature" value={signature} onChange={setSignature} />
        <FilePicker style={{flex: 1}} label="Tampon" value={tampon} onChange={setTampon} />
        <div className="flex items-center justify-center">
          <Button color="primary" onClick={_onSubmitSig}>Enregistrer</Button>
        </div>
      </Card>
      : null}
    </>
  )
}

export default Profile;