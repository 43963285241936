import React, {useState} from "react";
import useForm from "./context";
import { DatePicker, TextInput, Button } from "hydrogen";
import Calendar from "../../../components/Calendar";

const Step1 = () => {
  const [ctx, setCtx] = useForm();
  const [error, setError] = useState({});

  const _handleChange = (field, value) => setCtx({...ctx, [field]: value});
  const validate = () => {
    let _error = {};

    if (!ctx.patientName) _error.patientName = "Ce champ est obligatoire";
    if (!ctx.patientSurname) _error.patientSurname = "Ce champ est obligatoire";
    if (!ctx.patientBirthDate) _error.patientBirthDate = "Ce champ est obligatoire";
    if (!ctx.patientSecuNumber) _error.patientSecuNumber = "Ce champ est obligatoire";
    if (!ctx.patientPhone) _error.patientPhone = "Ce champ est obligatoire";
    if (!ctx.patientMail) _error.patientMail = "Ce champ est obligatoire";

    setError(_error);
    if (Object.keys(_error).length === 0) setCtx({...ctx, step: 2});
  }

  return (
    <div className="p-4">
      <h1 className="text-center text-4xl text-indigo-600">Informations patients</h1>
      <h2 className="text-center text-2xl text-indigo-300">1/5</h2>

      <div className="mt-2">
        <h3 className="text-2xl text-indigo-600">Patient:</h3>
        <div className="flex flex-wrap" style={{gap: 10}}>
          <TextInput invalid={error.patientName} label="Nom"    className="flex-1" value={ctx.patientName}    onChange={e => _handleChange("patientName", e)} />
          <TextInput invalid={error.patientSurname}label="Prénom" className="flex-1" value={ctx.patientSurname} onChange={e => _handleChange("patientSurname", e)} />
        </div>
        <Calendar invalid={error.patientBirthDate} label="Date de naissance" value={ctx.patientBirthDate} onChange={e => _handleChange("patientBirthDate", e)} />
        <TextInput invalid={error.patientSecuNumber}  label="Numéro de sécurité sociale" value={ctx.patientSecuNumber} onChange={e => _handleChange("patientSecuNumber", e)} />
        <TextInput invalid={error.patientPhone} label="Numéro de téléphone" value={ctx.patientPhone} onChange={e => _handleChange("patientPhone", e)} />
        <TextInput invalid={error.patientMail} label="Adresse mail" value={ctx.patientMail} onChange={e => _handleChange("patientMail", e)} />
        <TextInput invalid={error.patientAdress} label="Adresse postale" value={ctx.patientAdress} onChange={e => _handleChange("patientAdress", e)} />
      </div>
      <div className="px-8 flex justify-center items-center">
        <span className="cursor-pointer ass_gradient_1 text-white text-lg px-4 py-2 rounded-full"
              onClick={validate}>
          Suivant
        </span>
      </div>
    </div>
  )
}

export default Step1;