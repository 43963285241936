import React, {useState, useEffect} from "react";
import axios from "axios";
import md5 from "md5";
import config from "../../config"
import { Spinner } from "hydrogen";
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Verif = () => {
  const [verif, setVerif] = useState("loading"); //OK|KO|ERR
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const o = (new URLSearchParams(window.location.search)).get("o");
        const key = (new URLSearchParams(window.location.search)).get("key");

        console.log(o, key);

        if (md5(o + "c02546c0-6117-4ac5-a95b-ec76a1d482d0") !== key)
          return setVerif("KO");

        let res = await axios.get(`/api/demandes/${o}?populate=*`);
        setData(res?.data);

        if (!res?.data?.data?.attributes?.ordonnance)
          return setVerif("KO");

        setVerif("OK");
      } catch (e) {
        console.error(e);
        setVerif("ERR");
      }
    })()
  }, []);

  //`${config.strapi.apiUrl}${data.data.attributes.ordonnance}`
  console.log(data);
  if (verif === "loading") return <Spinner />
  if (verif === "KO") return (
    <p>Une erreur est survenue</p>
  )
  console.log(`${config.strapi.apiUrl}${data.data.attributes.ordonnance}`);

  return (
    <>
      <Document file={`${config.strapi.apiUrl}${data.data.attributes.ordonnance}`}>
        <Page pageNumber={1} />
      </Document>
    </>
  )
}

export default Verif;