const userRole = [
  {value: "superadmin",       label: "Superadmin", id: 3},
  {value: "Médecin",          label: "Médecin", id: 4},
  {value: "audioprothesiste", label: "AudioProthèsiste", id: 1},
]

const demandeStatus = [
  {label: "Demande non traité", value: "pending"}, 
  {label: "Demande en cours de verification (MG)", value: "pending-mg"}, 
  {label: "Demande en attente expertise ORL", value: "pending-orl"},
  {label: "Demande traitées", value: "done"}, 
  {label: "Annulé", value: "canceled"}, 
];


export {
  userRole,
  demandeStatus
}
