import React from "react";

const Steps = (props) => {
  const {steps, current, onChange} = props;

  return (
    <div className="flex" style={{gap: 5}}>
      {steps.map((step, i) => (
        <React.Fragment key={`${step}-${i}`}>
          <div className={`flex flex-col items-center justify-center`}>
            <span className={`text-2xl rounded-full w-12 h-12 flex items-center justify-center ` + 
                             `${i === current ? "border-blue-500 border-2" : 
                                i < current ? "border-green-400 border-2" : "border"}`}>
              {i}
            </span>
            {step ? 
              <span className={`text-gray-400`}>{step}</span> 
            : null}
          </div>
          {i !== steps.length - 1 ?
            <div className={`h-1 mt-5 flex-1 rounded ${i < current ? "bg-blue-500" : "bg-gray-300"}`} style={{minWidth: 50}}>

            </div>
          : null}
        </React.Fragment>
      ))}
    </div>
  )
}

export default  Steps;