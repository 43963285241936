import React, {useState, useEffect} from "react";
import {Button, TextInput, Spinner} from "hydrogen";
import useCtx from "./ctx";

const Step2 = () => {
  const [ctx, setCtx] = useCtx();
  const [error, setError] = useState({});

  const handleChange = (key, value) => setCtx({...ctx, [key]: value});
  const validate = () => {
    let _error = {};

    if (!ctx.socialReason) _error.socialReason = "Veuillez renseigner la raison sociale";
    if (!ctx.siret) _error.siret = "Veuillez renseigner le numéro de SIRET";
    if (!ctx.responsableSurname) _error.responsableSurname = "Veuillez renseigner le nom du responsable";
    if (!ctx.responsableName) _error.responsableName = "Veuillez renseigner le prénom du responsable";
    if (!ctx.audioReferentSurname) _error.audioReferentSurname = "Veuillez renseigner le nom du référent audio";
    if (!ctx.audioReferentName) _error.audioReferentName = "Veuillez renseigner le prénom du référent audio";
    if (!ctx.adeli) _error.adeli = "Veuillez renseigner le numéro ADELI";
    if (Object.keys(_error).length > 0) return setError(_error);
    setCtx({...ctx, step: 2});
    setError({});
  }

  return (
    <>
      <TextInput label="Raison sociale"
                 invalid={error.socialReason}
                 onChange={e => handleChange("socialReason", e)}
                 value={ctx.socialReason} />
      <TextInput label="SIRET"
                 invalid={error.siret}
                 onChange={e => handleChange("siret", e)}
                 value={ctx.siret} />
      <TextInput label="Prénom du Responsable"
                invalid={error.responsableSurname}
                onChange={e => handleChange("responsableSurname", e)}
                value={ctx.responsableSurname} />
      <TextInput label="Nom du Responsable"
                 invalid={error.responsableName}
                 onChange={e => handleChange("responsableName", e)}
                 value={ctx.responsableName} />
      <TextInput label="Prénom du référent AudioProthesiste"
                 invalid={error.audioReferentSurname}
                 onChange={e => handleChange("audioReferentSurname", e)}
                 value={ctx.audioReferentSurname} />
      <TextInput label="Nom du référent AudioProthesiste"
                  invalid={error.audioReferentName}
                  onChange={e => handleChange("audioReferentName", e)}
                  value={ctx.audioReferentName} />
      <TextInput label="n° Adeli" 
                  invalid={error.adeli}
                  onChange={e => handleChange("adeli", e)}
                  value={ctx.adeli} />
      <div className="flex justify-center">
        <Button onClick={validate}>Suivant</Button>
      </div>
    </>
  )
}

export default Step2;