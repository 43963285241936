import React, {createContext, useState, useEffect, useContext} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import useUI from "contexts/ui";
import { useHistory } from "react-router-dom";

const RoomContext = createContext();

const Default = {
  rooms: [],
};

const Provider = ({children}) => {
  const [ui] = useUI();
  const [ctx, setCtx] = useState({...Default});
  const history = useHistory();

  const refresh = async () => {
    try {
      let res = await axios.get("/api/visio-rooms?populate=*");
      res = res.data.data;

      res = await Promise.all(res.map(async (i) => {
        i.attributes.visio_room_users.data = await Promise.all(i.attributes.visio_room_users.data.map(async j => {
          let user = await axios.get(`/api/visio-room-users/${j.id}?populate=*`);
          return user.data;
        }));
        return i;
      }))
      setCtx({...ctx, rooms: res});
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue")
    }
  }

  const join = async (id) => {
    try {
      await axios.post(`/api/visio-room-users`, {
        data: {
          visio_room: id,
          users_permissions_user: ui.user.id,
          demande: null,
        }
      })
      await refresh();
      history.push(`/dashboard/rooms/${id}`);
      toast.success("Vous avez rejoint la salle");
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  }

  const disconnect = async () => {
    try {
      let res = await axios.get("/api/visio-room-users?populate=*");
      res = res.data.data;

      res = res.filter(i => i.attributes.users_permissions_user.data.id === ui.user.id);

      await Promise.all(res.map(async i => {
        await axios.delete(`/api/visio-room-users/${i.id}`);
      }))

      await refresh();
      toast.success("Appel terminé");
    } catch (e) {
      toast.error("Une erreur est survenue");
      console.error(e);
    }
  }

  const end = async (id) => {
    try {
      let res = await axios.get("/api/visio-rooms?populate=*");
      res = res.data.data;

      res = await Promise.all(res.map(async (i) => {
        i.attributes.visio_room_users.data = await Promise.all(i.attributes.visio_room_users.data.map(async j => {
          let user = await axios.get(`/api/visio-room-users/${j.id}?populate=*`);
          return user.data;
        }));
        return i;
      }))

      //delete all visio-room-users of id room
      let room = res.find(i => i.id == id);
      await Promise.all(room.attributes.visio_room_users.data.map(async i => {
        await axios.delete(`/api/visio-room-users/${i?.data?.id}`);
      }))

      await refresh();
      toast.success("Appel terminé");
    } catch (e) {
      toast.error("Une erreur est survenue");
      console.error(e);
    }
  }

  useEffect(() => {
    refresh();
    let interval = setInterval(() => {
      refresh();
    }
    , 5000);
    return () => clearInterval(interval);
  }, [])

  return (
    <RoomContext.Provider value={{ctx, setCtx, refresh, join, disconnect, end}}>
      {children}
    </RoomContext.Provider>
  )
};

const useRooms = () => useContext(RoomContext);
export default useRooms;
export {Provider};