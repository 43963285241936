/*

  Hydrogen required file

  do not delete this file

*/

import {createContext, useState, useEffect, useContext} from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import { useHistory } from 'react-router-dom';

const Context = createContext();

const Default = {
  mail: "",
  name: "",
  surname: "",
  password: "",
  confirm: "",
  socialReason: "",
  siret: "",
  responsableName: "",
  responsableSurname: "",
  audioReferentName: "",
  audioReferentSurname: "",
  adeli: "",

  loading: true,
  ok: false,
  step: 0,
}

const Provider = ({children}) => {
  const [ctx, setCtx] = useState({...Default});
  const history = useHistory();

  useEffect(() => {
    (async () => {
      let _ctx = {...ctx};

      _ctx.loading = true; setCtx({..._ctx});
      try {
        let query = new URLSearchParams(window.location.search);
        let res = await axios.get(`/api/tokens/${query.get("token")}`);
        res = res.data;

        _ctx.ok = res.ok;
        _ctx.name = res.name;
        _ctx.surname = res.surname;
        _ctx.mail = res.email;
        _ctx.password = res.password;
        _ctx.confirm = res.password;
        setCtx({..._ctx});

      } catch (e) {
        console.error(e);
      }
      _ctx.loading = false; setCtx({..._ctx});
    })()
  }, [])

  const handleSubmit = async () => {
    const {password, confirm, name, surname, mail} = ctx;
    let query = new URLSearchParams(window.location.search);

    if (!password) return toast.error("Veuillez entrer un mot de passe");
    if (password.length < 8) return toast.error("Le mot de passe doit faire au moins 8 caractères");
    if (password !== confirm) return toast.error("Les mots de passe ne correspondent pas");
    if (!name) return toast.error("Veuillez entrer un nom");
    if (!surname) return toast.error("Veuillez entrer un prénom");
    if (!mail) return toast.error("Veuillez entrer une adresse mail");
    if (!mail.includes("@")) return toast.error("L'adresse mail n'est pas valide");

    setCtx({...ctx, loading: true});
    try {
      let res = await axios.post(`/api/tokens/${query.get("token")}`, {
        name,
        surname,
        email: mail,
        password,
        ctx,
      })
      if (res.data.ok) {
        toast.success("Votre compte a bien été créé");
        history.push("/");
      } else {
        toast.error("L'utilisateur n'a pas pu être créé, vérifiez l'email et le token");
      }
      console.log(res);
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setCtx({...ctx, loading: false});
  }

  console.log(ctx);

  return (
    <Context.Provider value={[
      {...ctx, handleSubmit},
      setCtx
    ]}>
      {children}
    </Context.Provider>
  )
}

const useCtx = () => useContext(Context);

export default useCtx;
export {Provider, Default};