
import { Card, Table, Button, Modal, Spinner } from "hydrogen";
import {Edit} from "react-feather";
import { useHistory } from "react-router-dom";
import useGet from "hooks/useGet";
import moment from "moment";
import { demandeStatus } from "enums";

const Overview = () => {
  const history = useHistory();
  const [data, loading] = useGet("/api/demandes?populate=*", {defaultValue: [], format: e => e.data}, true);

  const _renderItem = (item, index) => (
    <tr key={item.id}>
      <td>{item?.attributes?.form?.patientName} {item?.attributes?.form?.patientSurname}</td>
      <td>{item?.attributes?.audioprothesiste?.data?.attributes?.username}</td>
      {/*<td>{demandeStatus.find(i => i.value === item?.attributes?.status)?.label}</td>*/}
      <td>{moment(item?.attributes?.createdAt)?.format("LL")}</td>
      <td>
        <Edit className="cursor-pointer" onClick={() => history?.push(`/dashboard/demandes/${item?.id}`)}/>
      </td>
    </tr>
  )

  if (loading) return <Spinner />
  return (
    <>
      {demandeStatus.map(i => (
        <Card className="flex flex-col mt-3" style={{gap: 10}} key={i.value}>
          <div className="flex justify-between items-center">
            <h3>{i.label}</h3>
          </div>

          <Table
            header={["Patient", "AudioProthesiste", "Date", "Actions"]}
            data={data.filter(j => j?.attributes?.status === i.value)}
            renderItem={_renderItem}
          />
        </Card>
      ))}
    </>
  )
}

export default Overview;