import {useState, useEffect} from "react";
import axios from "axios";

const useGet = (url, {defaultValue = null, format = (e) => e}, agregate) => {
  const [data, setData] = useState(defaultValue);
  const [loading, setLoading] = useState(true);

  const refresh = async () => {
    if (!url) return;
    if (agregate) {
      try {
        let result = [];
        let res = null;
        do {
          res = await axios.get(url + "&pagination[page]=" + (res?.data?.meta?.pagination?.page + 1 || 1));
          result = result.concat(res.data.data);
        } while (result.length < res?.data?.meta?.pagination?.total);
        setData(format({data: result, meta: res.data.meta}));
        setLoading(false);
      } catch (e) {
        console.error(e)
      }
    } else {
      try {
        let result = await axios.get(url);
        setData(format(result.data));
        setLoading(false);
      } catch (e) {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    refresh();
  }, [url])

  return [data, loading, refresh];
}

export default useGet;