import React, {useEffect} from "react";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {Download} from "react-feather";
import Template from "./_template";

const OrdonnancePdfInstance = ({  blob, url, loading , error, onChange  }) => {
  console.log(blob, url, loading, error)

  useEffect(() => {
    if (loading || blob === null) return;
    onChange({blob, url, error});
  }, [blob, url, error, loading])
  
  return (
    <></>
  )
}

const Ordonnance = (props) => {

  return (
    <>
      <PDFDownloadLink document={<Template {...props} />} fileName="somename.pdf" >
      {({  blob, url, loading , error  }) => (
        <>
          {loading ? 
            <></> 
          :
            <OrdonnancePdfInstance
              blob={blob}
              url={url}
              loading={loading}
              error={error}
              onChange={props.onChange}
            />
          }
        </>
      )}
      </PDFDownloadLink>
    </>
  )
}

export default Ordonnance;