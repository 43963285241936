
import { useEffect } from "react";
import { useLayout } from "layouts/Audioprothesiste";

const Password = () => {
  const [, setLayout] = useLayout();

  useEffect(() => setLayout({goBack: "/app/profile", title: "Sécurité"}), [])
  
  return (
    <>
      //TODO
    </>
  )
}

export default Password;