import { useEffect } from "react";
import useUI from "contexts/ui";
import { useHistory } from "react-router-dom";

const BlankLayout = (props) => {
  const {
    children
  } = props;

  //useEffect(() => {if (!ui.user) history.push("/login");}, [ui]);

  return (
    <div className="bg-gray-100">
      {children}
    </div>
  )
}

export default BlankLayout;