import React, { useEffect, useState } from "react";
import useUI from "contexts/ui";
import useRooms from "contexts/rooms";
import { useHistory, useParams } from "react-router-dom";
import {Card, Select, Button, Spinner} from "hydrogen";

const AudioProthesisteRoom = () => {
  const [ui] = useUI();
  const {id} = useParams();
  const {ctx, join, disconnect} = useRooms();
  const {rooms} = ctx;
  const [room, setRoom] = useState(null);
  const history = useHistory();

  const [aborted, setAborted] = useState(false);

  useEffect(() => {
    if (!rooms) return;
    let room = rooms.find(i => i.id == id);
    setRoom(room);

    let me = room?.attributes?.visio_room_users?.data?.find(i => i?.data?.attributes.users_permissions_user?.data?.id == ui.user.id)
    if (!me) {
      history.push("/dashboard/rooms");
    }
  }, [rooms, history, ui.user.id, id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log(room);
      if (room.attributes.visio_room_users.data.length === 1) {
        disconnect();
        history.push("/app/aborted");
      }
    }, 30000);
    return () => clearTimeout(timeout);
  }, [room])

  if (!room) return <Spinner />;

  return (
    <div className="flex flex-col" style={{height: "calc(100vh - 70px)"}}>
      {!aborted ? 
        <iframe
          title="visio"
          src={`${room.attributes.url}?minimal`}
          allow="camera *; microphone *; fullscreen *; speaker *; display-capture *; autoplay *"
          style={{width: "100%", height: "100%", flex: 1}}
        ></iframe>
      :
      <div className="flex flex-col items-center pt-8">
        <h2 className="text-xl mb-3">Aucun médecin disponible</h2>
        <Button color="primary" style={{marginRight: 0}} onClick={() => window.location.href = "https://www.doctolib.fr/cabinet-medical/paris/iyar-sante/booking/availabilities?motiveIds[]=3073606&placeId=practice-94221&practitionerId=23484040&specialityId=2&telehealth=false"}>Vers RDV avec un médecin</Button>
      </div>
      }
    </div>
  )
};

export default AudioProthesisteRoom;