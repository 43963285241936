import React, {useState} from "react";
import useForm from "./context";
import { DatePicker, TextInput, Button, Checkbox } from "hydrogen";

const Step2 = () => {
  const [ctx, setCtx] = useForm();
  const [error, setError] = useState({});

  const _handleChange = (field, value) => setCtx({...ctx, [field]: value});
  const validate = () => {
    let _error = {};

    setError(_error);
    if (Object.keys(_error).length === 0) setCtx({...ctx, step: 3});
  }

  return (
    <div className="p-4">
      <h1 className="text-center text-4xl text-indigo-600">AudioProthésiste</h1>
      <h2 className="text-center text-2xl text-indigo-300">2/5</h2>

      <div className="my-2">
        <h2 className="text-2xl text-indigo-500">Audioprothésiste référent: </h2>
        <div className="flex flex-wrap" style={{gap: 10}}>
          <TextInput className="flex-1" label="Nom" value={ctx.audioReferentName} onChange={e => _handleChange("audioReferentName", e) } />
          <TextInput className="flex-1" label="Prénom" value={ctx.audioReferentSurname} onChange={e => _handleChange("audioReferentSurname", e)} />
        </div>
        <TextInput label="n° Adeli" value={ctx.audioReferentAdeli} onChange={e => _handleChange("audioReferentAdeli", e)} />
      </div>

      <div className="px-8 flex justify-center items-center" style={{gap: 10}}>
        <span className="cursor-pointer bg-gray-400 text-white text-lg px-4 py-2 rounded-full"
              onClick={() => setCtx({...ctx, step: 1})}>
          Précédent
        </span>
        <span className="cursor-pointer ass_gradient_1 text-white text-lg px-4 py-2 rounded-full"
              onClick={validate}>
          Suivant
        </span>
      </div>
    </div>
  )
}

export default Step2;