import React, { useEffect } from "react";
import useForm, {Provider} from "./context";
import { useLayout } from "layouts/Audioprothesiste";

import Step1 from "./_step1";
import Step2 from "./_step2";
import Step3 from "./_step3";
import Step4 from "./_step4";
import Step5 from "./_step5";
import Recap from "./_Recap";
import Visio from "./_Visio";

const Form = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const edit = urlParams.get("edit");

  const [, setLayout] = useLayout();
  const [ctx] = useForm();

  useEffect(() => setLayout({goBack: "/app", title: edit ? `Edition demande n°${edit}` : "Nouvelle demande"}), []);

  return (
    <>
      {
        ctx.step === 1 ? <Step1 /> : 
        ctx.step === 2 ? <Step2 /> :
        ctx.step === 3 ? <Step3 /> :
        ctx.step === 4 ? <Step4 /> :
        ctx.step === 5 ? <Step5 /> :
        ctx.step === "recap" ? <Recap /> :
        ctx.step === "visio" ? <Visio /> :
        null
      }

    </>
  )
}

const Create = () => {
  //get ?edit=ID variable from url if update
  const urlParams = new URLSearchParams(window.location.search);
  const edit = urlParams.get("edit");

  return (
    <Provider edit={edit}>
      <Form />
    </Provider>
  );
};

export default Create;