
const config = {
  lang: "fr",
  driver: "strapi", //["firebase", "strapi"]
  strapi: {
    apiUrl: "https://api.audioclic.fr",
  },
  debug: true,
}

export default config;
