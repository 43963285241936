import React, {useState, useEffect} from "react";
import {Button, TextInput, Spinner} from "hydrogen";
import useCtx from "./ctx";

const Step3 = () => {
  const [ctx, setCtx] = useCtx();
  const [error, setError] = useState({});

  const handleChange = (key, value) => setCtx({...ctx, [key]: value});
  const validate = () => {
    let _error = {};

    if (!ctx.mail) _error.email = "Veuillez renseigner votre email";
    if (!ctx.password) _error.password = "Veuillez renseigner votre mot de passe";
    if (!ctx.confirm) _error.confirm = "Veuillez confirmer votre mot de passe";
    if (ctx.password !== ctx.confirm) _error.confirm = "Les mots de passe ne correspondent pas";
    if (Object.keys(_error).length > 0) return setError(_error);

    ctx.handleSubmit();
  }

  return (
    <>
      <TextInput label="Email" placeholder="elon.musk@spacex.com" onChange={e => handleChange("mail", e)} value={ctx.mail} />
      <TextInput type="password"
                  label="Mot de passe"
                  placeholder="·········" 
                  onChange={e => handleChange("password", e)} value={ctx.password} />
      <TextInput type="password"
                  label="Confirmation"
                  placeholder="·········" 
                  onChange={e => handleChange("confirm", e)} value={ctx.confirm} />
      <div className="flex justify-center">
        <Button onClick={validate}>Finir mon inscription</Button>
      </div>
    </>
  )
}

export default Step3;