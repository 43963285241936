import React from "react";
import {Select} from "hydrogen";

const YesNo = (props) => {
  const {value, style, label, onChange, ...rest} = props;

  return (
    <div className="flex items-center justify-center" style={{gap: 10}}>
      {label && <label className="text-gray-600">{label}</label>}
      <Select 
        {...rest}
        onChange={(e) => onChange(e?.value ?? null)}
        options={[
          {value: true, label: "Oui"},
          {value: false, label: "Non"},
        ]}
        value={value ? {value: true, label: "Oui"} : {value: false, label: "Non"}}
        style={{width: 100, minWidth: 100, marginBottom: 0, ...(style ?? {})}}
      />
    </div>
  )
}

export default YesNo;