import {useState, useEffect} from "react";
import PerfectScrollbar from 'react-perfect-scrollbar'
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {Settings, LogOut, Bell} from "react-feather";
import { useHistory } from "react-router-dom";
import {Provider} from "contexts/rooms";
import useRooms from "contexts/rooms";
import RingModal from "./_RingModal";

import useRouterConfig from "routes";
import useAuth from "hydrogen/core/hooks/useAuth";

import Logo from "assets/img/hydrogen.png";
import useUI from "contexts/ui";
import { toast } from "react-toastify";

const Dashboard = (props) => {
  const {children} = props;
  const [ui] = useUI();
  const history = useHistory();
  const {ctx} = useRooms();
  const {rooms} = ctx;

  const {routes, layouts} = useRouterConfig();
  const {logout} = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const [ignoreVisioUserId, setIgnoreVisioUserId] = useState([]);
  const [ringingRooms, setRingingRooms] = useState([]);

  useEffect(() => {if (!ui.user) history.push("/")}, [ui.user])

  useEffect(() => {
    const ignoreVisioUserId = localStorage.getItem("ignoreVisioUserId");
    if (ignoreVisioUserId) setIgnoreVisioUserId(JSON.parse(ignoreVisioUserId));
  }, [])

  useEffect(() => {
    localStorage.setItem("ignoreVisioUserId", JSON.stringify(ignoreVisioUserId));
  }, [ignoreVisioUserId])
  
  const isIAmInCall = () => {
    if (!rooms) return false;
    return rooms.filter(i => i.attributes.visio_room_users.data.filter(u => u?.data?.attributes?.users_permissions_user?.data?.id === ui.user.id).length > 0).length > 0;
  }

  useEffect(() => {
    if (!rooms) return;
    const n = rooms
    .map(i => ({
      ...i, 
      attributes: {
        ...i.attributes, 
        visio_room_users: {
          data: i.attributes.visio_room_users.data.filter(u => u?.data?.attributes?.users_permissions_user?.data)
                                                  .filter(u => !ignoreVisioUserId.includes(u.data.id))
                                                  
        }
      }
    })) //ignore visioUserTimestamp when call rejected
    .filter(i => i.attributes.visio_room_users.data.length > 0) //keep only rooms with users
    setRingingRooms(n);
  }, [rooms, ignoreVisioUserId])

  const _logout = () => {
    logout();
    history.push("/");
  }

  const renderSidebarLinks = () => {
    const links = routes.filter(r => r.hide !== true);
    
    return (
      <>
        {links.map(link => (
          <div className="mt-2 ml-4 transition-transform transform hover:translate-x-3" key={`${link.layout}/${link.name}`}>
            <Link className={`text-white text-lg cursor-pointer rounded py-1 px-2 ` + 
                              `${history.location.pathname === `/${link.layout}/${link.route}` ? "bg-indigo-400" : ""}`}
                  to={`${layouts.filter(layout => layout.name === link.layout)[0].route}/${link.route}`}>
              <FontAwesomeIcon className="mr-2 w-5" icon={link.icon ?? faChevronRight} />
              {link.name}
            </Link>
          </div>
        ))}
      </>
    )
  }

  return (
      <div className="min-h-screen flex flex-row">
        <div className={`flex flex-col min-h-screen h-screen max-h-screen overflow-y-hidden pt-4 pb-4 w-60 ${sidebarOpen ? "ml-0" : "-ml-60"}`}
            style={{minWidth: "15rem", maxWidth: "15rem", transition: "margin 0.2s ease-out", backgroundColor: "#2F3C4E"}}>
          <PerfectScrollbar className="w-60 overflow-y-scroll p-y-2">
            <img alt="logo" src={Logo} className="w-full mb-8" />
            {renderSidebarLinks()}
          </PerfectScrollbar>
        </div>
        <div className="flex flex-col-reverse w-full h-full max-h-screen min-h-screen">
          <PerfectScrollbar className="flex-1 bg-gray-100 max-h-full p-5">
            {children}
          </PerfectScrollbar>
          {/* Header */}
          <div className={`flex items-center justify-between w-full max-h-14 h-14 shadow-md bg-white px-4`}
              style={{minHeight: "3.5rem"}}>
            {/* LEFT */}
            <div className="flex items-center" style={{gap: 20}}>
              <FontAwesomeIcon icon={faBars} size="2x" color="#312E81"
                              className="cursor-pointer"
                              onClick={() => setSidebarOpen(!sidebarOpen)} />
            </div>

            <div></div>

            {/* RIGHT */}
            <div className="flex text-indigo-900 mr-2" style={{gap: 10}}>
              <Bell className="cursor-pointer" />
              <Link to="/dashboard/profile">
                <Settings className="cursor-pointer"/>
              </Link>
              <LogOut   className="cursor-pointer" onClick={_logout}/>
            </div>
          </div>
        </div>
        {ringingRooms.length > 0 && !isIAmInCall() ? <RingModal rooms={ringingRooms} ignored={ignoreVisioUserId} setIgnored={setIgnoreVisioUserId} /> : null}
      </div>
  )
}

const LayoutHOC = ({children}) => <Provider><Dashboard>{children}</Dashboard></Provider>

export default LayoutHOC;