import React, {useEffect} from "react";
import useRooms from "contexts/rooms";
import Phone from "../assets/phone.mp3"

const RingModal = (props) => {
  const {join} = useRooms();
  const {rooms, ignored, setIgnored} = props;
  
  const audio = new Audio(Phone);
  audio.loop = true;

  const ignoreVisioUsers = (room) => {
    let n = room.attributes.visio_room_users.data.map(u => u.data.id);
    setIgnored([...ignored, ...n]);
  }

  useEffect(() => {//ring a phone sound
    console.log("play audio");
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [])
  

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h3 className="font-semibold text-xl">Appel entrant</h3>
        {rooms.map(room => (
          <div className="flex flex-col items-center justify-between mt-4 pb-3" key={room.id} style={{borderBottom: "1px solid lightgrey"}}>
            <div className="">
              <span className="font-semibold">{room.attributes.title}</span>
              <div className="flex flex-col">
                {room.attributes.visio_room_users.data.map(user => (
                  <p className="ml-2" key={user.data.id}>- {user?.data?.attributes?.users_permissions_user?.data?.attributes?.username} / {user?.data?.attributes?.users_permissions_user?.data?.attributes?.email}</p>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-center w-full mt-2" style={{gap: 10}}>
              <button className="cursor-pointer bg-red-500 text-white px-4 py-2 rounded-lg" onClick={() => ignoreVisioUsers(room)}>Rejeter</button>
              <button className="cursor-pointer bg-green-500 text-white px-4 py-2 rounded-lg" onClick={() => join(room.id)}>Accepter</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RingModal;