import React from 'react';
import {Card, Button} from "hydrogen";
import { useHistory } from 'react-router-dom';

const DemandeConfirmation = () => {
  const history = useHistory();

  return (
    <Card>
      <h3 className="text-2xl text-indigo-600 text-center">Votre demande a bien été envoyé</h3>
      <div className="mt-4 flex justify-center items-center">
        <Button color="secondary" onClick={() => history.push("/app")}>Retour à l'accueil</Button>
        <Button color="primary" style={{marginRight: 0}} onClick={() => window.location.href = "https://www.doctolib.fr/cabinet-medical/paris/iyar-sante/booking/availabilities?motiveIds[]=3073606&placeId=practice-94221&practitionerId=23484040&specialityId=2&telehealth=false"}>Vers RDV avec un médecin</Button>
      </div>
    </Card>
  );
}

export default DemandeConfirmation;