import React, {useState, useEffect, useId} from "react";
import { toast } from "react-toastify";
import {Download, Trash, Upload} from "react-feather";
import { Spinner } from "hydrogen";
import axios from "axios";
import config from "config";
import {v4 as uuidv4} from "uuid";

const FileUploader = (props) => {
  const {
    className,
    style,
    onChange,
    value,
    label,
    accept,
    disabled,
  } = props;
  const [id] = useState(uuidv4());
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (disabled) return;
    try {
      const imgFile = e.target.files[0];
      let formData = new FormData();
      formData.append("files", imgFile, `${uuidv4()}_${imgFile.name}`);

      let res = await axios.post("/api/upload", formData);
      onChange(res.data[0].url);
      e.target.value = null;
    } catch (e) {
      console.error(e.message);
      toast.error(e.message);
    }
    setLoading(false);
  }

  const handleRemove = async () => {
    if (!disabled) onChange(null)
  }

  return (
    <div className={`fileUploader ${className ?? ""} mb-3`} style={{minWidth: 200, ...style}}>
      {label ? <span className="block text-gray-600 mb-1">{label}</span> : null}
      <div className={`flex`}>
        <label className={`border rounded-l flex-1 h-9 flex items-center px-2 overflow-hidden whitespace-nowrap `} for={id}>
          {value ? (value.split("/").pop()) : "Choisir un fichier"}
        </label>
        {loading ? 
          <div className="flex items-center justify-center ml-1 h-9">
            <Spinner />
          </div>
        : 
          <>
            <label className={`flex items-center px-2 border-t border-b border-r cursor-pointer hover:bg-gray-100 ${!value ? "rounded-r" : ""}`} for={id}>
              <Upload size={24} />
            </label>
            {value ? <a href={`${config.strapi.apiUrl}${value}`} target="_blank" className={`flex items-center border-t border-r border-b px-2 cursor-pointer hover:bg-gray-100`}>
              <Download size={24} />
            </a>: null}
            {value ? <div className={`flex items-center border-t border-r border-b rounded-r cursor-pointer px-2 hover:bg-gray-100`} onClick={handleRemove}>
              <Trash size={24} />
            </div>: null}
          </>
        }
      </div>
        {!disabled ? 
          <input id={id} type="file" placeholder="Sélectionner votre fichier" accept={accept ?? "*"} onChange={handleSubmit} style={{visibility: "hidden", display: "none"}} />
        : null}
    </div>
  )
}

FileUploader.defaultProps = {
  style: {},
  className: ""
}

export default FileUploader;