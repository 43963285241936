import {createContext, useState, useContext, useEffect} from "react";
import moment from "moment";
import useUI from "contexts/ui";
import axios from "axios";

const Context = createContext();

const Default = {
  step: 1,

  // Step 1
  patientName: "",
  patientsurname: "",
  patientBirthDate: null,
  patientSecuNumber: "",
  patientPhone: "",
  patientMail: "",
  patientAdress: "",
  
  // Step 2
  audioReferentName: "",
  audioReferentSurname: "",
  audioReferentAdeli: "",

  // Step 3
  patientPathologies: false,
  patientPathologiesDescription: "",
  patientPrescription: false,
  patientPrescriptionDescription: "",
  patientAntecedents: false,
  patientAntecedentsDescription: "",
  patientHearingAids: false,
  patientHearingAidsDescription: "",
  patientExpositions: false,
  patientExpositionsDescription: "",
  
  // Step 4
  patientHearingLossConditions: null,
  patientHearingLossLocation: null,
  patientHearingLossApparition: "",
  patientHearingLossProgression: null,
  patientHearingLossSymptoms: [],
  patientHearingLossSymptomsDescription: "",
  patientHearingLossConsequences: [],
  patientHearingLossDiscomfort: 5,

  // Step 5
  patientAudiogram: null,
  patientTympanogramLeft: null,
  patientTympanogramRight: null,
}

const Provider = ({children, edit}) => {
  const [ctx, setCtx] = useState({...Default});
  const [ui] = useUI();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (edit) {
        console.log("edit");
        let {data} = await axios.get(`/api/demandes/${edit}`);
        console.log(data);
        setCtx({
          ...ctx,
          ...data?.data?.attributes?.form,
          patientBirthDate: moment(data?.data?.attributes?.form?.patientBirthDate),
        });
      } else {
        console.log("new");
        setCtx({
          ...ctx,
          audioReferentName: ui.user.audioReferentName,
          audioReferentSurname: ui.user.audioReferentSurname,
          audioReferentAdeli: ui.user.adeli,
        });
      }
      setLoading(false);
    })()
  }, [edit])

  return (
    <Context.Provider value={[ctx, setCtx]}>
      {!loading ? children : null}
    </Context.Provider>
  )
}

const useForm = () => useContext(Context);

export default useForm;
export {Provider};