import React, {useState} from "react";
import useForm from "./context";
import {toast} from "react-toastify";
import axios from "axios";
import useUI from "contexts/ui";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {Download} from "react-feather";
import config from "config";
import { Checkbox } from "hydrogen";

const patientHearingLossConditionsOptions = [{label: "Milieu bruyant", value: "loud"}, {label: "Gene Permanente", value: "parmanent"}];
const patientHearingLossLocationOptions = [{label: "Oreille gauche", value: "left"}, {label: "Oreille droite", value: "right"}, {label: "Les deux", value: "both"}];
const patientHearingLossProgressionOptions = [{label: "Soudainement", value: "sudden"}, {label: "Aggravation progressive", value: "progressive"}];
const patientHearingLossSymptomsOptions = [{label: "Acouphènes", value: "acouphenes"}, {label: "Sensibilité aux bruits", value: "sensibilite"}, {label: "Vertiges", value: "vertiges"}, {label: "Troubles de l'équilibre", value: "equilibre"}, {label: "Aucun", value: "aucun"}];
const patientHearingLossConsequencesOptions = [{label: "Retentissement sur la vie quotidienne", value: "daily"}, {label: "Isolement social", value: "social"}, {label: "Aucun", value: "aucun"}];

const Recap = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const edit = urlParams.get("edit");

  const [ui] = useUI();
  const [ctx, setCtx] = useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [rgpdAccordPatient, setRgpdAccordPatient] = useState(false);
  const [rgpdCgu, setRgpdCgu] = useState(false);
  const [localError, setLocalError] = useState({});

  const _handleSubmit = async () => {
    setLoading(true);
    try {
      let _localError = {};
      if (!rgpdAccordPatient) _localError.rgpdAccordPatient = true;
      if (!rgpdCgu) _localError.rgpdCgu = true;

      setLocalError(_localError);
      if (Object.keys(_localError).length > 0) {
        throw new Error("Veuillez accepter les conditions d'utilisation");
      }

      let _ctx = {...ctx};
      delete _ctx.step;

      if (edit) {
        await axios.put(`/api/demandes/${edit}`, {data: {
          form: {
            ..._ctx,
            patientBirthDate: _ctx.patientBirthDate.toDate()
          },
          audioprothesiste: ui.user.id
        }})
        toast.success("Demande mise à jour avec succès");
      } else {
        await axios.post("/api/demandes", {data: {
          form: {
            ..._ctx,
            patientBirthDate: _ctx.patientBirthDate.toDate()
          },
          audioprothesiste: ui.user.id
        }})
        toast.success("Demande envoyée avec succès");
      }

      history.push("/app/demandes/confirm");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  }

  return (
    <div className="p-4">
      <h1 className="text-center text-4xl text-indigo-600">Récapitulatif</h1>

      <div className="mt-2 flex flex-col" style={{gap: 10}}>
        <div className="border border-indigo-600 rounded p-2">
          <h3 className="text-2xl text-indigo-600">Informations patients:</h3>
          <p>identité: {ctx.patientName} {ctx.patientSurname}</p>
          <p>date de naissance: {ctx.patientBirthDate.format("LL")} ({ctx.patientBirthDate.fromNow()})</p>
          <p>n° de sécu: {ctx.patientSecuNumber}</p>
          <p>téléphone: {ctx.patientPhone}</p>
          <p>email: {ctx.patientMail}</p>
          <p>adresse postale: {ctx.patientAdress}</p>
        </div>
        <div className="border border-indigo-600 rounded p-2">
          <h3 className="text-2xl text-indigo-600">AudioProthésiste:</h3>
          <p>identité: {ctx.audioReferentName} {ctx.audioReferentSurname}</p>
          <p>n° Adeli: {ctx.audioReferentAdeli}</p>
        </div>
        <div className="border border-indigo-600 rounded p-2">
          <h3 className="text-2xl text-indigo-600">Antécédents:</h3>
          <p>Autres maladie ?: {ctx.patientPathologies ? "oui" : "non"} {ctx.patientPathologiesDescription ? `(${ctx.patientPathologiesDescription})` : ""}</p>
          <p>Médicaments ?: {ctx.patientPrescription ? "oui" : "non"} {ctx.patientPrescriptionDescription ? `(${ctx.patientPrescriptionDescription})` : ""}</p>
          <p>Antécédents médicaux ou chirurgicaux ?: {ctx.patientAntecedents ? "oui" : "non"} {ctx.patientAntecedentsDescription ? `(${ctx.patientAntecedentsDescription})` : ""} </p>
          <p>Antécédents appareillage ?: {ctx.patientHearingAids ? "oui" : "non"} {ctx.patientHearingAidsDescription ? `(${ctx.patientHearingAidsDescription})`: ""}</p>
          <p>Exposition ?: {ctx.patientExpositions ? "oui" : "non"} {ctx.patientExpositionsDescription ? `(${ctx.patientExpositionsDescription})` : ""}</p>
        </div>
        <div className="border border-indigo-600 rounded p-2">
          <h3 className="text-2xl text-indigo-600">Bilan:</h3>
          <p>Situation de la gène: {ctx.patientHearingLossConditions ? patientHearingLossConditionsOptions.find(i => i.value === ctx.patientHearingLossConditions)?.label : ""}</p>
          <p>Localisation de la gène: {ctx.patientHearingLossLocation ? patientHearingLossLocationOptions.find(i => i.value === ctx.patientHearingLossLocation)?.label : ""}</p>
          <p>Date d'apparition des symptomes: {ctx.patientHearingLossApparition}</p>
          <p>Qualification de l'apparition des symptomes: {ctx.patientHearingLossProgression ? patientHearingLossProgressionOptions.find(i => i.value === ctx.patientHearingLossProgression)?.label : ""}</p>
          <p>Autres symptomes: {" "}
            {ctx.patientHearingLossSymptoms && typeof(ctx.patientHearingLossSymptoms) === "string" ? patientHearingLossSymptomsOptions.find(i => i.value === ctx.patientHearingLossSymptoms)?.label :
             ctx.patientHearingLossSymptoms && typeof(ctx.patientHearingLossSymptoms) === "object" ? 
              ctx.patientHearingLossSymptoms.map(i => i.label).join(", ")
             : ""} 
            {ctx.patientHearingLossSymptomsDescription ? `(${ctx.patientHearingLossSymptomsDescription})` : ""}
          </p>
          <p>Conséquences: {" "}
            {ctx.patientHearingLossConsequences && typeof(ctx.patientHearingLossConsequences) === "string" ? patientHearingLossConsequencesOptions.find(i => i.value === ctx.patientHearingLossConsequences)?.label : 
             ctx.patientHearingLossConsequences && typeof(ctx.patientHearingLossConsequences) === "object" ? ctx.patientHearingLossConsequences.map(i => i.label).join(", ") : ""}
          </p>
          <p>Echelle de la gène (1-10): {ctx.patientHearingLossDiscomfort}</p>
        </div>
        <div className="border border-indigo-600 rounded p-2">
          <h3 className="text-2xl text-indigo-600">Pièces jointes</h3>
          <div className="flex justify-center items-center" style={{gap: 15}}>
            {ctx.patientAudiogram ? 
              <a href={`${config.strapi.apiUrl}${ctx.patientAudiogram}`} target="_blank" className="flex items-center justify-center flex-col">
                <Download size={56} color="#6d61fa" />
                Audiogramme
              </a>
            : null}
            {ctx.patientTympanogramLeft ? 
              <a href={`${config.strapi.apiUrl}${ctx.patientTympanogramLeft}`} target="_blank" className="flex items-center justify-center flex-col">
                <Download size={56} color="#6d61fa" />
                photo des tympans (gauche)
              </a>
            : null}
            {ctx.patientTympanogramRight ? 
              <a href={`${config.strapi.apiUrl}${ctx.patientTympanogramRight}`} target="_blank" className="flex items-center justify-center flex-col">
                <Download size={56} color="#6d61fa" />
                photo des tympans (droite)
              </a>
            : null}
          </div>
        </div>
      </div>

      {/* RGPD */}
      <div className="border border-indigo-600 rounded p-2 mt-2">
        <Checkbox label="Je certifie que le patient est d'accord pour être mis en relation avec un médecin dans le but d'étudier la demande d'appareilage et qu'il autorise le partage de renseignements avec le médecin et l'audioprothésiste"
          invalid={localError.rgpdAccordPatient}
          value={rgpdAccordPatient}
          onChange={setRgpdAccordPatient}
        />
        <Checkbox label="J'accepte les conditions générales d'utilisations du site"
          invalid={localError.rgpdCgu}
          value={rgpdCgu}
          onChange={setRgpdCgu}
        />
      </div>
      
      <div className="mt-6 flex items-center justify-center" style={{gap: 10}}>
        <span className="cursor-pointer bg-gray-400 text-white text-lg px-4 py-2 rounded-full"
              onClick={() => setCtx({...ctx, step: 4})}>
          Précédent
        </span>
        <span className="cursor-pointer ass_gradient_1 text-white text-lg px-4 py-2 rounded-full"
              onClick={_handleSubmit}>
          {edit ? "Mettre à jour" : "Envoyer"}
        </span>
      </div>
    </div>
  )
}

export default Recap;