import React, {useState, useEffect} from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import moment from "moment";
import { TextInput } from 'hydrogen';

const MyCalendar = (props) => {
  const {value, onChange, className, style, invalid, label} = props;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>

      <MobileDatePicker
          label="Date mobile"
          inputFormat="MM/DD/YYYY"
          value={value ? value : null}
          onChange={onChange}
          renderInput={(params) => (
            <div {...params} className={`mb-3 ${className}`} style={{minWidth: 200, ...style}} >
              {label ? 
                <span className="block text-gray-600 mb-1">{label}</span>
              : <></>}
              <input disabled value={value ? value.format("LL") : ""}
                     className="h-9 w-full px-2 rounded bg-white border cursor-pointer" />
              {invalid ? 
                <span className="block text-red-500">{invalid}</span>
              : <></>}
            </div>
          )}
          />

    </LocalizationProvider>
  )
}

export default MyCalendar;