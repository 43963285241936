import React, {useState} from "react";
import useForm from "./context";
import { DatePicker, TextInput, Button, Checkbox } from "hydrogen";
import YesNo from "../../../components/YesNo";

const Step3 = () => {
  const [ctx, setCtx] = useForm();
  const [error, setError] = useState({});

  const _handleChange = (field, value) => setCtx({...ctx, [field]: value});
  const validate = () => {
    let _error = {};

    setError(_error);
    if (Object.keys(_error).length === 0) setCtx({...ctx, step: 4});
  }

  return (
    <div className="p-4">
      <h1 className="text-center text-4xl text-indigo-600">Antécédents</h1>
      <h2 className="text-center text-2xl text-indigo-300">3/5</h2>

      <div className="my-2">
        <div className="flex items-center flex-wrap mt-2" style={{gap: 10, minHeight: 50}}>
          <YesNo value={ctx.patientPathologies} onChange={e => _handleChange("patientPathologies", e)} label="Avez-vous d’autres maladies ?" />
          {ctx.patientPathologies && <TextInput style={{flex: 1, marginBottom: 0}} placeholder="lesquels ?" value={ctx.patientPathologiesDescription} onChange={e => _handleChange("patientPathologiesDescription", e)} />}
        </div>
        <div className="flex items-center flex-wrap mt-2" style={{gap: 10, minHeight: 50}}>
          <YesNo value={ctx.patientPrescription} onChange={e => _handleChange("patientPrescription", e)} label="Prenez-vous des médicaments au long cours ?" />
          {ctx.patientPrescription && <TextInput style={{flex: 1, marginBottom: 0}} placeholder="lesquels ?" value={ctx.patientPrescriptionDescription} onChange={e => _handleChange("patientPrescriptionDescription", e)} />}
        </div>
        <div className="flex items-center flex-wrap mt-2" style={{gap: 10, minHeight: 50}}>
          <YesNo value={ctx.patientAntecedents} onChange={e => _handleChange("patientAntecedents", e)} label="Avez-vous des antécédents médicaux ou chirurgicaux ORL ?" />
          {ctx.patientAntecedents && <TextInput style={{flex: 1, marginBottom: 0}} placeholder="lesquels ?" value={ctx.patientAntecedentsDescription} onChange={e => _handleChange("patientAntecedentsDescription", e)} />}
        </div>
        <div className="flex items-center flex-wrap mt-2" style={{gap: 10, minHeight: 50}}>
          <YesNo value={ctx.patientHearingAids} onChange={e => _handleChange("patientHearingAids", e)} label="Avez-vous ou êtes déjà été appareillé ?" />
          {/*ctx.patientHearingAids && <TextInput style={{flex: 1, marginBottom: 0}} placeholder="lesquels ?" value={ctx.patientHearingAidsDescription} onChange={e => _handleChange("patientHearingAidsDescription", e)} />*/}
        </div>
        <div className="flex items-center flex-wrap mt-2" style={{gap: 10, minHeight: 50}}>
          <YesNo value={ctx.patientExpositions} onChange={e => _handleChange("patientExpositions", e)} label="Avez-vous ou êtes-vous exposé au bruit ?" />
          {/*ctx.patientExpositions && <TextInput style={{flex: 1, marginBottom: 0}} placeholder="lesquels ?" value={ctx.patientExpositionsDescription} onChange={e => _handleChange("patientExpositionsDescription", e)} />*/}
        </div>
      </div>

      <div className="px-8 flex justify-center items-center" style={{gap: 10}}>
        <span className="cursor-pointer bg-gray-400 text-white text-lg px-4 py-2 rounded-full"
              onClick={() => setCtx({...ctx, step: 2})}>
          Précédent
        </span>
        <span className="cursor-pointer ass_gradient_1 text-white text-lg px-4 py-2 rounded-full"
              onClick={validate}>
          Suivant
        </span>
      </div>
    </div>
  )
}

export default Step3;