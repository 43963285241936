import React, {useState, useEffect} from "react";
import {Card, Select, Button, Spinner} from "hydrogen";
import { useHistory, useParams } from "react-router-dom";
import useGet from "hooks/useGet";
import moment from "moment";
import { demandeStatus } from "enums";
import {Download} from "react-feather";
import config from "config";
import axios from "axios";
import {toast} from "react-toastify";
import useUI from "contexts/ui";
import Ordonnance from "./Ordonnance";
import {v4 as uuidv4} from "uuid";

const patientHearingLossConditionsOptions = [{label: "Milieu bruyant", value: "loud"}, {label: "Gene Permanente", value: "parmanent"}];
const patientHearingLossLocationOptions = [{label: "Oreille gauche", value: "left"}, {label: "Oreille droite", value: "right"}, {label: "Les deux", value: "both"}];
const patientHearingLossProgressionOptions = [{label: "Soudainement", value: "sudden"}, {label: "Aggravation progressive", value: "progressive"}];
const patientHearingLossSymptomsOptions = [{label: "Acouphènes", value: "acouphenes"}, {label: "Sensibilité aux bruits", value: "sensibilite"}, {label: "Vertiges", value: "vertiges"}, {label: "Troubles de l'équilibre", value: "equilibre"}, {label: "Aucun", value: "aucun"}];
const patientHearingLossConsequencesOptions = [{label: "Retentissement sur la vie quotidienne", value: "daily"}, {label: "Isolement social", value: "social"}, {label: "Aucun", value: "aucun"}];

const MedecinDemande = () => {
  const {id} = useParams();
  const [ui] = useUI();
  const history = useHistory();
  const [data] = useGet(`/api/demandes/${id}?populate=*`, {defaultValue: null, format: e => e.data});
  const [ctx, setCtx] = useState({});
  const [status, setStatus] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const [pdfState, setPdfState] = useState(null);
  const [pdfStateTmp, setPdfStateTmp] = useState(null);
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    if (!data) return
    setCtx(data?.attributes?.form);
    setStatus(data?.attributes?.status);
    setLoading(false);
    setPdfUrl(data?.attributes?.ordonnance);
  }, [data])

  const _changeStatus = async (newStatus) => {
    try {
      setStatus(newStatus);
      await axios.put(`/api/demandes/${id}`, {data: {status: newStatus}});
      toast.success("La demande a été mise à jour");
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  }

  const _changePdf = async (pdf) => {
    try {
      if (!pdf || !pdf.blob) return;
      let blob = pdf.blob;
      let formData = new FormData();
      formData.append("files", blob, `${uuidv4()}_ordonnance.pdf`);
      let res = await axios.post(`/api/upload`, formData);
      let url = res.data[0].url;
      await axios.put(`/api/demandes/${id}`, {data: {ordonnance: url}});
      setPdfData(pdf);
      setPdfUrl(url);
      toast.success("L'ordonnance a été mise à jour");
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  }

  console.log(`${config.strapi.apiUrl}${ui?.user?.signature}`);

  if (loading) return <div className="flex justify-center items-center mt-12"><Spinner size="lg" /></div>
  return (
    <Card>
      <div className="flex justify-between">
        <h3>Demande {id}</h3>
        <div className="flex items-center justify-center" style={{gap: 5}}>
          {/*<Play className="mb-2 cursor-pointer" size={32} color="#03aeef"/>*/}
          <Select options={demandeStatus} value={status && demandeStatus.find(i => i.value === status) ? demandeStatus.find(i => i.value === status) : null} onChange={e => _changeStatus(e.value)}/>
        </div>
      </div>
      <div>
        <div className="mt-2 flex flex-col" style={{gap: 10}}>
          <div className="border border-indigo-600 rounded p-2">
            <h3 className="text-2xl text-indigo-600">Informations patients:</h3>
            <p>identité: {ctx.patientName} {ctx.patientSurname}</p>
            <p>date de naissance: {moment(ctx.patientBirthDate).format("LL")} ({moment(ctx.patientBirthDate).fromNow()})</p>
            <p>n° de sécu: {ctx.patientSecuNumber}</p>
            <p>téléphone: {ctx.patientPhone}</p>
            <p>email: {ctx.patientMail}</p>
<p>adresse postale: {ctx.patientAdress}</p>
          </div>
          <div className="border border-indigo-600 rounded p-2">
            <h3 className="text-2xl text-indigo-600">AudioProthésiste:</h3>
            <p>identité: {ctx.audioReferentName} {ctx.audioReferentSurname}</p>
            <p>n° Adeli: {ctx.audioReferentAdeli}</p>
          </div>
          <div className="border border-indigo-600 rounded p-2">
            <h3 className="text-2xl text-indigo-600">Antécédents:</h3>
            <p>Autres maladie ?: {ctx.patientPathologies ? "oui" : "non"} {ctx.patientPathologiesDescription ? `(${ctx.patientPathologiesDescription})` : ""}</p>
            <p>Médicaments ?: {ctx.patientPrescription ? "oui" : "non"} {ctx.patientPrescriptionDescription ? `(${ctx.patientPrescriptionDescription})` : ""}</p>
            <p>Antécédents médicaux ou chirurgicaux ?: {ctx.patientAntecedents ? "oui" : "non"} {ctx.patientAntecedentsDescription ? `(${ctx.patientAntecedentsDescription})` : ""} </p>
            <p>Antécédents appareillage ?: {ctx.patientHearingAids ? "oui" : "non"} {ctx.patientHearingAidsDescription ? `(${ctx.patientHearingAidsDescription})`: ""}</p>
            <p>Exposition ?: {ctx.patientExpositions ? "oui" : "non"} {ctx.patientExpositionsDescription ? `(${ctx.patientExpositionsDescription})` : ""}</p>
          </div>
          <div className="border border-indigo-600 rounded p-2">
            <h3 className="text-2xl text-indigo-600">Bilan:</h3>
            <p>Situation de la gène: {ctx.patientHearingLossConditions ? patientHearingLossConditionsOptions.find(i => i.value === ctx.patientHearingLossConditions)?.label : ""}</p>
            <p>Localisation de la gène: {ctx.patientHearingLossLocation ? patientHearingLossLocationOptions.find(i => i.value === ctx.patientHearingLossLocation)?.label : ""}</p>
            <p>Date d'apparition des symptomes: {ctx.patientHearingLossApparition}</p>
            <p>Qualification de l'apparition des symptomes: {ctx.patientHearingLossProgression ? patientHearingLossProgressionOptions.find(i => i.value === ctx.patientHearingLossProgression)?.label : ""}</p>
            <p>Autres symptomes: {" "}
              {ctx.patientHearingLossSymptoms && typeof(ctx.patientHearingLossSymptoms) === "string" ? patientHearingLossSymptomsOptions.find(i => i.value === ctx.patientHearingLossSymptoms)?.label :
              ctx.patientHearingLossSymptoms && typeof(ctx.patientHearingLossSymptoms) === "object" ? 
              ctx.patientHearingLossSymptoms.map(i => i.label).join(", ")
              : ""} 
              {ctx.patientHearingLossSymptomsDescription ? `(${ctx.patientHearingLossSymptomsDescription})` : ""}
            </p>
            <p>Conséquences: {" "}
              {ctx.patientHearingLossConsequences && typeof(ctx.patientHearingLossConsequences) === "string" ? patientHearingLossConsequencesOptions.find(i => i.value === ctx.patientHearingLossConsequences)?.label : 
              ctx.patientHearingLossConsequences && typeof(ctx.patientHearingLossConsequences) === "object" ? ctx.patientHearingLossConsequences.map(i => i.label).join(", ") : ""}
            </p>
            <p>Echelle de la gène (1-10): {ctx.patientHearingLossDiscomfort}</p>
          </div>
          <div className="border border-indigo-600 rounded p-2">
            <h3 className="text-2xl text-indigo-600">Pièces jointes</h3>
            <div className="flex justify-center items-center" style={{gap: 15}}>
            {ctx.patientAudiogram ? 
              <a href={`${config.strapi.apiUrl}${ctx.patientAudiogram}`} target="_blank" className="flex items-center justify-center flex-col">
                <Download size={56} color="#6d61fa" />
                Audiogramme
              </a>
            : null}
            {ctx.patientTympanogramLeft ? 
              <a href={`${config.strapi.apiUrl}${ctx.patientTympanogramLeft}`} target="_blank" className="flex items-center justify-center flex-col">
                <Download size={56} color="#6d61fa" />
                photo des tympans (gauche)
              </a>
            : null}
            {ctx.patientTympanogramRight ? 
              <a href={`${config.strapi.apiUrl}${ctx.patientTympanogramRight}`} target="_blank" className="flex items-center justify-center flex-col">
                <Download size={56} color="#6d61fa" />
                photo des tympans (droite)
              </a>
            : null}
            </div>
          </div>
          <div className="border border-indigo-600 rounded p-2">
            <h3 className="text-2xl text-indigo-600">Ordonnance</h3>
            {ui.user.role.name === "medecin" && !pdfData && !pdfUrl ? 
              <div className="flex items-center mt-4" style={{gap: 10}}>
                <Select placeholder="Appareillage" 
                        options={[{label: "Gauche", value: "left"}, {label: "Droite", value: "right"}, {label: "Bilatéral", value: "both"}]} 
                        value={pdfStateTmp} 
                        onChange={setPdfStateTmp}
                        className="flex-1" />
                <Button onClick={() => setPdfState(pdfStateTmp?.value)}>Générer</Button>
              </div>
            : null}
            {pdfState === "left" ? 
              <Ordonnance uid={id} data={ctx} side={pdfState} ui={ui} onChange={_changePdf}/> :
            pdfState === "right" ?
              <Ordonnance uid={id} data={ctx} side={pdfState} ui={ui} onChange={_changePdf}/> :
            pdfState === "both" ?
              <Ordonnance uid={id} data={ctx} side={pdfState} ui={ui} onChange={_changePdf}/>
              : null
            }
            {pdfUrl ? 
              <a className="flex items-center justify-center flex-col" href={`${config.strapi.apiUrl}${pdfUrl}`} target="_blank">
                <Download size={56} color="#6d61fa" />
                ordonnance
              </a>
            : null}
            {pdfState || pdfUrl ?
              <div className="flex justify-center items-center" style={{gap: 15}}>
                <Button onClick={() => {setPdfState(null); setPdfData(null); setPdfUrl(null); axios.put(`/api/demandes/${id}`, {data: {ordonnance: null}})}} color="danger" className="mt-4" style={{marginRight: 0}}>
                  Supprimer l'ordonnance
                </Button>
              </div>
            : null}
          </div>
        </div>
      </div>
      <div className="flex justify-center -mb-4 mt-12" style={{gap: 10}}>
        <Button color="secondary" onClick={() => history.push("/dashboard/demandes")}>Retour</Button>
      </div>
    </Card>
  )
}

export default MedecinDemande;