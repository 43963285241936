import React, {useState, useEffect} from "react";
import {Button, Card, Select, Spinner, TextInput} from "hydrogen";
import { userRole } from "enums";
import { useHistory, useParams } from "react-router-dom";
import useGet from "hooks/useGet";
import {toast} from "react-toastify";
import axios from "axios";

const User = () => {
  const history = useHistory();
  const {id} = useParams();
  const [user, loading, refresh] = useGet(`/api/users/${id}?populate=*`, {defaultValue: {}, format: (e) => e});

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(null);

  const [socialReason, setSocialReason] = useState("");
  const [siret, setSiret] = useState("");
  const [responsableName, setResponsableName] = useState("");
  const [responsableSurname, setResponsableSurname] = useState("");
  const [audioReferentName, setAudioReferentName] = useState("");
  const [audioReferentSurname, setAudioReferentSurname] = useState("");
  const [adeli, setAdeli] = useState("");

  useEffect(() => {
    setName(user.name);
    setSurname(user.surname);
    setEmail(user.email);
    setRole(user?.role?.name);
    setSocialReason(user?.socialReason);
    setSiret(user?.siret);
    setResponsableName(user?.responsableName);
    setResponsableSurname(user?.responsableSurname);
    setAudioReferentName(user?.audioReferentName);
    setAudioReferentSurname(user?.audioReferentSurname);
    setAdeli(user?.adeli);
  }, [user])

  const _handleSubmit = async () => {
    try {
      await axios.put(`/api/users/${id}`, {
          name,
          surname,
          ...(role ? {role: userRole.find(i => i.value === role)?.id}: {}),
          socialReason,
          siret,
          responsableName,
          responsableSurname,
          audioReferentName,
          audioReferentSurname,
          adeli
      })
      await refresh();
      toast.success("L'utilisateur a bien été modifié");
      history.push("/dashboard/users");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }

  if (loading) return (<Spinner />);
  return (
    <Card>
      <div className="flex justify-between">
        <h4>Utilisateur: {user.surname} {user.name}</h4>
        <Select options={userRole} value={role ? userRole.find(i => i.value === role) : null} onChange={e => e ? setRole(e.value) : null}/>
      </div>
      <div className="mt-4">
        <div className="flex" style={{gap: 10}}>
          <TextInput label="Nom" placeholder="Nom..." className="flex-1" style={{minWidth: 200}} value={name} onChange={setName}/>
          <TextInput label="Prénom" placeholder="Prénom..." className="flex-1" style={{minWidth: 200}} value={surname} onChange={setSurname} />
        </div>
        <TextInput label="Email" placeholder="Email..." style={{minWidth: 200}} value={email} onChange={setEmail} disabled />
        <TextInput label="Raison sociale" value={socialReason} onChange={setSocialReason}/>
        <TextInput label="Siret" value={siret} onChange={setSiret} />
        <TextInput label="Nom du responsable" value={responsableName} onChange={setResponsableName} />
        <TextInput label="Prénom du responsable" value={responsableSurname} onChange={setResponsableSurname} />
        <TextInput label="Nom du référent audio" value={audioReferentName} onChange={setAudioReferentName} />
        <TextInput label="Prénom du référent audio" value={audioReferentSurname} onChange={setAudioReferentSurname}/>
        <TextInput label="Adeli" value={adeli} onChange={setAdeli} />
      </div>
      <div className="flex justify-center -mb-4 mt-4">
        <Button color="secondary" onClick={() => history.push("/dashboard/users")}>Retour</Button>
        <Button className="ml-3" onClick={_handleSubmit}>Enregistrer</Button>
      </div>
    </Card>
  )
}

export default User;