import React, {useState, useEffect} from "react";
import useForm from "./context";
import FilePicker from "components/FilePicker";

const Step5 = () => {
  const [ctx, setCtx] = useForm();
  const [error, setError] = useState({});

  const [file, setFile] = useState(null);

  const _handleChange = (field, value) => setCtx({...ctx, [field]: value});
  const validate = () => {
    let _error = {};

    setError(_error);
    if (Object.keys(_error).length === 0) setCtx({...ctx, step: "recap"});
  }

  return (
    <div className="p-4">
      <h1 className="text-center text-4xl text-indigo-600">Pièces jointes</h1>
      <h2 className="text-center text-2xl text-indigo-300">5/5</h2>

      <div className="my-2 flex flex-wrap" style={{gap: 10}}>
        <FilePicker style={{flex: 1, minWidth: 350}} label="Audiogramme" value={ctx.patientAudiogram} onChange={e => _handleChange("patientAudiogram", e)} />
        <FilePicker style={{flex: 1, minWidth: 350}} label="Photo des tympans (gauche)" value={ctx.patientTympanogramLeft} onChange={e => _handleChange("patientTympanogramLeft", e)} />
        <FilePicker style={{flex: 1, minWidth: 350}} label="Photo des tympans (droite)" value={ctx.patientTympanogramRight} onChange={e => _handleChange("patientTympanogramRight", e)} />
      </div>

      <div className="px-8 flex justify-center items-center" style={{gap: 10}}>
        <span className="cursor-pointer bg-gray-400 text-white text-lg px-4 py-2 rounded-full"
              onClick={() => setCtx({...ctx, step: 4})}>
          Précédent
        </span>
        <span className="cursor-pointer ass_gradient_1 text-white text-lg px-4 py-2 rounded-full"
              onClick={validate}>
          Suivant
        </span>
      </div>
    </div>
  )
}

export default Step5;