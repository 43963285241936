import React, { useEffect, useState } from "react";
import useUI from "contexts/ui";
import useRooms from "contexts/rooms";
import { useHistory } from "react-router-dom";
import {Card, Select, Button, Spinner} from "hydrogen";
import axios from "axios";
import {toast} from "react-toastify"

const AudioProthesisteRooms = () => {
  const [ui] = useUI();
  const {ctx, refresh} = useRooms();
  const {rooms} = ctx;
  const history = useHistory();
  const [noRoomAvailable, setNoRoomAvailable] = useState(null);
  
  const join = async (id) => {
    try {
      await axios.post(`/api/visio-room-users`, {
        data: {
          visio_room: id,
          users_permissions_user: ui.user.id,
          demande: null,
        }
      })
      await refresh();
      history.push("/app/rooms/" + id)
      toast.success("Vous avez rejoint la salle");
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  }

  useEffect(() => {
    //find a free room
    if (!rooms) return;
    const n = rooms
    .map(i => ({
      ...i, 
      attributes: {
        ...i.attributes, 
        visio_room_users: {
          data: i.attributes.visio_room_users.data.filter(u => u?.data?.attributes?.users_permissions_user?.data)
                                                  
        }
      }
    })) //ignore visioUserTimestamp when call rejected
    .filter(i => i.attributes.visio_room_users.data.length === 0) //keep only rooms without users

    if (n.length === 0) {
      setNoRoomAvailable(true);
    } else {
      setNoRoomAvailable(false);
      join(n[0].id);
    }
  }, [])

  if (!rooms) return <Spinner />;

  return (
    <div className="flex flex-col items-center pt-8" style={{height: "calc(100vh - 70px)"}}>
      {noRoomAvailable ? 
        <>
          <h2 className="text-xl mb-3">Aucune salle de visio-conférence disponible</h2>
          <Button color="primary" style={{marginRight: 0}} onClick={() => window.location.href = "https://www.doctolib.fr/cabinet-medical/paris/iyar-sante/booking/availabilities?motiveIds[]=3073606&placeId=practice-94221&practitionerId=23484040&specialityId=2&telehealth=false"}>Vers RDV avec un médecin</Button>
        </>
      : null}
    </div>
  )
};

export default AudioProthesisteRooms;