import React, {useState, useEffect} from "react";
import { Card, Table, Button, Modal, Spinner } from "hydrogen";
import {PhoneCall, PhoneOff} from "react-feather"
import useRooms from "contexts/rooms";

const SuperAdminRoom = () => {
  const {ctx, join, end} = useRooms();
  const {rooms} = ctx;

  console.log(rooms);

  const _renderItem = (item, index) => (
    <tr key={item.id}>
      <td>{item?.attributes?.title} {item?.attributes?.form?.patientSurname}</td>
      <td>{item?.attributes?.visio_room_users?.data?.filter(i => i?.data?.attributes?.users_permissions_user?.data).length}</td>
      <td className="flex items-center" style={{gap: 10}}>
        <PhoneCall color="#0C0" className="cursor-pointer" onClick={() => join(item.id)} />
        <PhoneOff  color="#D00" className="cursor-pointer" onClick={() => end(item.id)} />
      </td>
    </tr>
  )
  
  return (
    <>
      <Table
        header={["title", "utilisateurs connectés", "Actions"]}
        data={rooms}
        renderItem={_renderItem} />
    </>
  );
};

export default SuperAdminRoom;