import React from "react";
import {Document, Image, Page, StyleSheet, Text, View, Font} from "@react-pdf/renderer";
import moment from "moment";
import Roboto from "../../../assets/fonts/Roboto.ttf";
import RobotoBold from "../../../assets/fonts/Roboto-Bold.ttf";
import config from "config";
import QRCode from "qrcode";
import md5 from "md5";

Font.register({
  family: "Roboto",
  src: Roboto,
});

Font.register({
  family: "Roboto-bold",
  src: RobotoBold,
});

const styles = StyleSheet.create({
  title: {
    textAlign: "center",
    fontFamily: "Roboto-bold",
  },
  bold: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Roboto-bold",
  },
  text: {
    fontSize: 12,
    fontWeight: "normal",
    fontFamily: "Roboto",
  },
  right: {
    textAlign: "right",
  },
  page: {
    padding: 30,
    fontFamily: "Roboto",
    height: 800
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  imageContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  image: {
    width: 200,
    height: 100,
    marginLeft: 10,
    marginRight: 10,
    objectFit: "contain",
  }
});

const Template = (props) => {
  const {data, side, ui, uid} = props;

  console.log(props);
  console.log(`${config.strapi.apiUrl}${ui.user.signature}`)

  const signature = ui.user.signature ? `${config.strapi.apiUrl}${ui.user.signature}` : null;
  const tampon = ui.user.tampon ? `${config.strapi.apiUrl}${ui.user.tampon}` : null;
  const urlPromise = QRCode.toDataURL(`${window.location.origin}/verif?o=${uid}&key=${md5(uid + "c02546c0-6117-4ac5-a95b-ec76a1d482d0")}`);

  return (
    <Document>
      <Page size="A4" orientation="portrait">
        <View style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>CDS MEDICO-DENTAIRE IYAR DENTAL</Text>
            <Text style={styles.title}>85/87, avenue SIMON BOLIVAR 75019 PARIS</Text>
            <Text style={styles.title}>FINESS N°750070377</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.bold}>Dr {ui?.user?.name?.toUpperCase()} {ui?.user?.surname}</Text>
            <Text style={styles.text}>O.R.L et chirurgien cervico-facial</Text>
            <Text style={styles.text}>RPPS {ui?.user?.adeli}</Text>
          </View>
          <View style={styles.section}>
            <Text style={[styles.text, styles.right]}>le {moment().format("LL")}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Patient: {data?.patientName} {data?.patientSurname} ({data?.patientBirthDate ? moment(data?.patientBirthDate).format("LL") : ""})</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Faire par audioprothésiste DE</Text>
            <Text style={styles.text}>
              Appareillage auditif {side === "left" ? "gauche" : 
                                    side === "right" ? "droit" : "bilatéral"}
            </Text>
          </View>
          <View style={styles.imageContainer}>
            {signature ? <Image src={signature} style={styles.image} /> : null}
            {tampon ? <Image src={tampon} style={styles.image} /> : null}
          </View>
          <View style={{
            position: "absolute", 
            bottom: 20, 
            right: 20, 
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end"
          }}>
            <Image
              src={urlPromise}
              style={styles.image}
            />
            <Text style={styles.text}>
              Vérifiez cette ordonnance grâce à audioclic.fr
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default Template;