import { useEffect, useState } from "react";
import {Button} from "hydrogen";
import useUI from "contexts/ui";
import { useLayout } from "layouts/Audioprothesiste";
import useAuth from "hydrogen/core/hooks/useAuth";
import {Lock, ChevronRight} from "react-feather";
import { useHistory } from "react-router-dom";

const Profile = () => {
  const {logout} = useAuth();
  const [ui, setUI] = useUI();
  const [layout, setLayout] = useLayout();
  const history = useHistory();

  useEffect(() => {
    setLayout({goBack: "/app", title: "Mon profil"})
  }, [])
  
  const _logout = () => {
    logout();
    history.push("/login");
  }

  return (
    <div>
      <div className="bg-white px-4 py-4 flex justify-between items-center">
        <div>
          <p className="text-xl font-semibold">{ui.user.surname} {ui.user.name}</p>
          <p>{ui.user.email}</p>
        </div>
        <div>
          <span className="text-white cursor-pointer px-4 py-2 rounded-full ass_gradient_1" onClick={() => history.push("/app/profile/me")}>
            Modifier
          </span>
        </div>
      </div>
      <h4 className="ml-2 my-4 font-semibold">Sécurité</h4>
      <div className="bg-white px-4 py-4 flex justify-between items-center cursor-pointer" onClick={() => history.push("/app/profile/password")}>
        <div className="flex items-center" style={{gap: 20}}>
          <Lock size={36} className="cursor-pointer" color="#bfbfbf" />
          <span className="text-lg cursor-pointer">Mot de passe</span>
        </div>
        <ChevronRight color="#bfbfbf" className="cursor-pointer" size={36} />
      </div>
      <div className="flex items-center justify-center">
        <p className="ass_gradient_1 text-white cursor-pointer px-6 py-3 mt-5 rounded-full text-lg" onClick={_logout}>Me déconnecter</p>
      </div>
    </div>
  )
}

export default Profile;