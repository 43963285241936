import React, {useState, useEffect} from "react";
import {Button, TextInput, Spinner} from "hydrogen";
import useCtx from "./ctx";

const Step1 = () => {
  const [ctx, setCtx] = useCtx();
  const [error, setError] = useState({});

  const handleChange = (key, value) => setCtx({...ctx, [key]: value});
  const validate = () => {
    let _error = {};

    if (!ctx.name) _error.name = "Veuillez renseigner votre prénom";
    if (!ctx.surname) _error.surname = "Veuillez renseigner votre nom";
    if (Object.keys(_error).length > 0) return setError(_error);
    setCtx({...ctx, step: 1});
    setError({});
  }

  return (
    <>
      <TextInput label="Nom" placeholder="Musk" invalid={error.name} onChange={e => handleChange("name", e)} value={ctx.name} />
      <TextInput label="Prénom" placeholder="Elon" invalid={error.surname} onChange={e => handleChange("surname", e)} value={ctx.surname} />

      <div className="flex justify-center">
        <Button onClick={validate}>Suivant</Button>
      </div>
    </>
  )
}

export default Step1;