import React, {useState} from "react";
import useForm from "./context";
import { DatePicker, TextInput, Button, Checkbox, Select, NumInput } from "hydrogen";

const patientHearingLossConditionsOptions = [{label: "Milieu bruyant", value: "loud"}, {label: "Gene Permanente", value: "parmanent"}];
const patientHearingLossLocationOptions = [{label: "Oreille gauche", value: "left"}, {label: "Oreille droite", value: "right"}, {label: "Les deux", value: "both"}];
const patientHearingLossProgressionOptions = [{label: "Soudainement", value: "sudden"}, {label: "Aggravation progressive", value: "progressive"}];
const patientHearingLossSymptomsOptions = [{label: "Acouphènes", value: "acouphenes"}, {label: "Sensibilité aux bruits", value: "sensibilite"}, {label: "Vertiges", value: "vertiges"}, {label: "Troubles de l'équilibre", value: "equilibre"}, {label: "Aucun", value: "aucun"}];
const patientHearingLossConsequencesOptions = [{label: "Retentissement sur la vie quotidienne", value: "daily"}, {label: "Isolement social", value: "social"}, {label: "Aucun", value: "aucun"}];

const Step4 = () => {
  const [ctx, setCtx] = useForm();
  const [error, setError] = useState({});

  const _handleChange = (field, value) => setCtx({...ctx, [field]: value});
  const validate = () => {
    let _error = {};

    setError(_error);
    if (Object.keys(_error).length === 0) setCtx({...ctx, step: 5});
  }

  return (
    <div className="p-4">
      <h1 className="text-center text-4xl text-indigo-600">Bilan</h1>
      <h2 className="text-center text-2xl text-indigo-300">4/5</h2>

      <div className="my-2">
        <Select label="Votre perte auditive vous gêne-t-elle uniquement en milieu bruyant (ex : au restaurant) ou de façon permanente ?"
                options={patientHearingLossConditionsOptions}
                value={ctx.patientHearingLossConditions ? patientHearingLossConditionsOptions.find(i => i.value === ctx.patientHearingLossConditions) : null}
                onChange={e => _handleChange("patientHearingLossConditions", e ? e.value : null)} />
        <Select label="Votre perte auditive touche-t-elle une seule oreille ou les deux ?"
                options={patientHearingLossLocationOptions}
                value={ctx.patientHearingLossLocation ? patientHearingLossLocationOptions.find(i => i.value === ctx.patientHearingLossLocation) : null}
                onChange={e => _handleChange("patientHearingLossLocation", e ? e.value : null)} />
        <TextInput label="Depuis combien de temps avez-vous remarqué cette baisse ?"
                   value={ctx.patientHearingLossApparition}
                   onChange={e => _handleChange("patientHearingLossApparition", e)} />
        <Select label="La baisse auditive est-elle apparue soudainement ou s’aggrave-t-elle progressivement avec le temps ?"
                options={patientHearingLossProgressionOptions}
                value={ctx.patientHearingLossProgression ? patientHearingLossProgressionOptions.find(i => i.value === ctx.patientHearingLossProgression) : null}
                onChange={e => _handleChange("patientHearingLossProgression", e ? e.value : null)} />
        <Select label="Présentez-vous d’autres symptômes comme des acouphènes, une sensibilité aux bruits, des vertiges, des troubles de l’équilibre ? "
                options={patientHearingLossSymptomsOptions}
                value={ctx.patientHearingLossSymptoms}
                onChange={e => _handleChange("patientHearingLossSymptoms", e)}
                isMulti />
        {(ctx.patientHearingLossSymptoms.length > 0 && JSON.stringify(ctx.patientHearingLossSymptoms) !== JSON.stringify([{label: "Aucun", value: "aucun"}])) ? 
          <TextInput label="Précisions ?" value={ctx.patientHearingLossSymptomsDescription} onChange={e => _handleChange("patientHearingLossSymptomsDescription", e)} /> : <></>}
        <Select label="La baisse d’audition entraine-t-elle un retentissement dans votre vie quotidienne ? Vous isole-t-elle ?"
                options={patientHearingLossConsequencesOptions}
                value={ctx.patientHearingLossConsequences}
                onChange={e => _handleChange("patientHearingLossConsequences", e)}
                isMulti />
        
        <NumInput label="Sur une échelle de 0 à 10 (0=aucune gêne à 10=gêne insupportable), à combien estimez-vous votre gêne causée par votre perte auditive ?"
                  value={ctx.patientHearingLossDiscomfort}
                  onChange={e => _handleChange("patientHearingLossDiscomfort", e)}
                  min={1}
                  max={10}
                  slider />
      </div>
      
      <div className="px-8 flex justify-center items-center" style={{gap: 10}}>
        <span className="cursor-pointer bg-gray-400 text-white text-lg px-4 py-2 rounded-full"
              onClick={() => setCtx({...ctx, step: 3})}>
          Précédent
        </span>
        <span className="cursor-pointer ass_gradient_1 text-white text-lg px-4 py-2 rounded-full"
              onClick={validate}>
          Suivant
        </span>
      </div>
    </div>
  )
}

export default Step4;