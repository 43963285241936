
import {useState, useEffect} from "react";
import { Button, Card, Table, Modal, TextInput } from "hydrogen";
import {Edit, Plus, } from "react-feather";
import { useHistory, useParams } from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import useGet from "hooks/useGet";
import moment from "moment";
import {Trash} from "react-feather";

const Overview = () => {
  const history = useHistory();

  const [users] = useGet('/api/users?populate=*', {defaultValue: [], format: (e) => e});
  const [invites, , refresh] = useGet(`/api/invites`, {defaultValue: [], format: (e) => e.data});

  const [isInviteOpen, setInviteOpen] = useState(false);
  
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (!isInviteOpen) {
      setName("");
      setSurname("");
      setEmail("");
      setLoading(false);
    }
  }, [isInviteOpen])

  const _handleSubmit = async () => {
    setLoading(true)
    try {
      await axios.post(`/api/invites`, {
        name,
        surname,
        email,
        role: "audioprothesiste"
      })
      await refresh();
      setInviteOpen(false);
      toast.success("L'utilisateur a bien été invité");
    } catch (e) {
      toast.error(e.message);
      console.error(e);
    }
    setLoading(false)
  }
  const _handleDelete = async (invite) => {
    try {
      await axios.delete(`/api/invites/${invite.id}`);
      await refresh();
      toast.success("L'utilisateur a bien été supprimé");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }

  console.log(users)

  const _renderItemUser = (item, index) => (
    <tr key={item?.id}>
      <td>{item?.id}</td>
      <td>{item?.username}</td>
      <td>{item?.email}</td>
      <td>{item?.role?.name}</td>
      <td>
        <Edit className="cursor-pointer" onClick={() => history.push(`/dashboard/users/${item?.id}`)} />
      </td>
    </tr>
  )

  const _renderItemInvite = (item, index) => (
    <tr key={item?.id}>
      <td>{item?.id}</td>
      <td>{item?.attributes?.surname} {item?.attributes?.name}</td>
      <td>{item?.attributes?.email}</td>
      <td>{item?.attributes?.role}</td>
      <td>{item?.attributes?.createdAt ? moment(item.attributes.createdAt).format("LL") : ""}</td>
      <td>
        <Trash className="cursor-pointer" color="red" onClick={() => _handleDelete(item)} />
      </td>
    </tr>
  )

  return (
    <div className="flex flex-col" style={{gap: 10}}>
      <Card className="flex flex-col" style={{gap: 10}}>
        <div className="flex justify-between items-center">
          <h3>Utilisateurs</h3>
          <Button onClick={() => setInviteOpen(true)}><Plus /></Button>
        </div>
        <Table 
          data={users ?? []}
          header={["ID", "Nom / Prénom", "Email", "Role", "Actions"]}
          renderItem={_renderItemUser}
        />
      </Card>
      <Card className="flex flex-col" style={{gap: 10}}>
        <div className="flex justify-between items-center">
          <h3>Invitations</h3>
          <Button onClick={() => setInviteOpen(true)}><Plus /></Button>
        </div>
        <Table 
          data={invites ?? []}
          header={["ID", "Nom / Prénom", "Email", "Role", "Envoyé le", "Actions"]}
          renderItem={_renderItemInvite}
        />
      </Card>
      <Modal isOpen={isInviteOpen} onClose={() => setInviteOpen(false)}>
          <h3>Inviter un utilisateur</h3>
          <div className="mt-3 mb-3">
            <div className="flex" style={{gap: 10}}>
              <TextInput className="flex-1" label="Nom" placeholder="Nom..." value={name} onChange={setName} />
              <TextInput className="flex-1" label="Prénom" placeholder="Prénom..." value={surname} onChange={setSurname} />
            </div>
            <TextInput label="Email" placeholder="Email..." value={email} onChange={setEmail} />
          </div>
          <div className="-mb-3 mt-3 flex justify-center items-center">
            <Button color="secondary" onClick={() => setInviteOpen(false)}>Annuler</Button>
            <Button color="primary" onClick={_handleSubmit} loading={loading}>Inviter</Button>
          </div>
        </Modal>
    </div>
  )
}

export default Overview;