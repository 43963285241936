import React, {useState, useEffect} from "react";
import useRooms from "contexts/rooms";
import { useParams } from "react-router-dom";
import {Card, Select, Button, Spinner} from "hydrogen";
import useUI from "contexts/ui";
import { useHistory } from "react-router-dom";
import {PhoneCall, PhoneOff} from "react-feather"

const SuperAdminRoom = () => {
  const [ui] = useUI();
  const {ctx, end} = useRooms();
  const history = useHistory();
  const {rooms} = ctx;
  const {id} = useParams();
  const [room, setRoom] = useState(null);

  useEffect(() => {
    if (!rooms) return;
    let room = rooms.find(i => i.id == id);
    setRoom(room);

    let me = room?.attributes?.visio_room_users?.data?.find(i => i?.data?.attributes.users_permissions_user?.data?.id == ui.user.id)
    if (!me) {
      history.push("/dashboard/rooms");
    }
  }, [id, rooms]);

  console.log(room);

  if (!room) return <Spinner />;

  return (
    <div className="flex flex-col" style={{height: "calc(100vh - 100px)", gap: 10}}>
      <Card>
        <h1>Room: {id}</h1>
        <div className="flex items-center">
          <div className="flex-1">
            <h2>Utilisateurs connectés:</h2>
            {room?.attributes?.visio_room_users?.data?.filter(i => i?.data?.attributes?.users_permissions_user?.data)
                                                      .map(i => (
              <div key={i.id}>
                - {i?.data?.attributes.users_permissions_user?.data?.attributes?.name}{" "}
                {i?.data?.attributes.users_permissions_user?.data?.attributes?.surname}
              </div>
            ))}
          </div>
          <div>
            <PhoneOff  color="#D00" className="cursor-pointer" onClick={() => end(id)} />
          </div>
        </div>
      </Card>
      <iframe
        src={`${room.attributes.url}?minimal`}
        allow="camera *; microphone *; fullscreen *; speaker *; display-capture *; autoplay *"
        style={{width: "100%", height: "100%", flex: 1}}
      ></iframe>
    </div>
  )
}

export default SuperAdminRoom;