
import { useEffect, useState } from "react";
import useUI from "contexts/ui";
import useGet from "hooks/useGet";
import {useLayout} from "layouts/Audioprothesiste";
import {Plus} from "react-feather";
import { useHistory, Link } from "react-router-dom";
import { Spinner } from "hydrogen";
import moment from "moment";
import { demandeStatus } from "enums";
import FilePicker from "components/FilePicker";

const Overview = () => {
  const [ui] = useUI();
  const [layout, setLayout] = useLayout();
  const history = useHistory();
  const [data, loading] = useGet("/api/demandes?populate=*", {defaultValue: [], format: e => e.data.filter(i => i.attributes?.audioprothesiste?.data?.id === ui.user.id)}, true);
  console.log(ui);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setLayout({goBack: null, title: null})
  }, [])

  if (loading) return <div className="flex justify-center items-center mt-12"><Spinner size="lg" /></div>
  return (
    <div className="flex flex-col" style={{gap: 10}}>
      <h2 className="mt-4 px-4 text-4xl font-semibold text-indigo-600">Demandes en cours</h2>
      <div>
        {/* TODO rechercher les demandes en cours */}
      </div>
      <div>
        {!data || data.length === 0 ? <p className="text-center">Aucune demande en cours</p> : <>
          {data.map((e, i) => (
            <Link className="flex justify-between items-center border rounded py-4 px-4 mx-4 bg-white" key={e.id} to={`/app/demandes/${e.id}`}>
              <span>{e.attributes.form.patientName} {e.attributes.form.patientSurname} ({moment(e.attributes.createdAt).format("LLL")})</span>
              <span className="border rounded py-1 px-2">
                {demandeStatus.find(i => i.value === e?.attributes?.status)?.label}
              </span>
            </Link>
          ))}
        </>}
      </div>
      {/*<FilePicker value={file} onChange={setFile} />*/}
      <Plus size={96} className="absolute bottom-14 right-14 ass_gradient_1 rounded-full cursor-pointer" color="white" onClick={() => history.push("/app/demande")}/>
    </div>
  )
}

export default Overview;