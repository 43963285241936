import React, {useEffect} from 'react';
import { useLayout } from 'layouts/Audioprothesiste';

const Me = () => {
  const [, setLayout] = useLayout();

  useEffect(() => setLayout({goBack: '/app/profile', title: 'Mon profil'}), [])
  
  return (
    <span>//TODO</span>
  );
}

export default Me;