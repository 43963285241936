
import { Card } from "hydrogen";

const Overview = () => {
  return (
    <div className="flex" style={{gap: 10}}>
      <Card className="flex-1" style={{minWidth: 200}}>
        <h3>Utilisateurs</h3>
        <h2 className="text-center mt-4 text-blue-600">1</h2>
      </Card>
      <Card className="flex-1" style={{minWidth: 200}}>
        <h3>Demandes</h3>
        <h6 className="text-center mt-4 text-blue-600">Aucune demande en attente</h6>
      </Card>
      <Card className="flex-1" style={{minWidth: 200}}>
        <h3>Notifications</h3>
        <h6 className="text-center mt-4 text-blue-600">Aucune notification en attente</h6>
      </Card>
    </div>
  )
}

export default Overview;