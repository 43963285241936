import {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { Button, Card, TextInput, Spinner } from "hydrogen";
import useAuth from "hydrogen/core/hooks/useAuth";
import useUI from "contexts/ui";
import Loading from "pages/Loading";
import useCtx, { Provider } from "./ctx";

import Steps from "hydrogen/components/Steps";
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"

const Register = ({match}) => {
  const history = useHistory();
  const [ui] = useUI();
  const [ctx] = useCtx();

  useEffect(() => {
    if (ui.user && ui.user.role.name === "audioprothesiste") history.push("/app")
    if (ui.user) history.push("/dashboard")
  }, [ui.user, history])

  if (ctx.loading) return <Loading />
  if (!ctx.ok) return (
    <div className="w-screen h-screen flex items-center justify-center">
      <h4>Lien d'inscription invalide</h4>
    </div>
  )
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <Card>
        <h4 className="text-center mb-3">Inscription</h4>
        <Steps steps={["Mon profil", "Mon entreprise", "Mon compte"]} current={ctx.step} />
        <div className="mt-6"></div>
        {ctx.step === 0 ? <Step1 /> :
         ctx.step === 1 ? <Step2 /> :
         ctx.step === 2 ? <Step3 /> : <Step1 />}
      </Card>
    </div>
  )
}

const RegisterHOC = () => <Provider><Register /></Provider>
export default RegisterHOC;