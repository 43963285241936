import {useState, useEffect, createContext, useContext} from "react";
import {useHistory} from "react-router-dom";
import useUI from "contexts/ui";
import { Provider } from "contexts/rooms";
import {Phone} from "react-feather";
import axios from "axios";
import {toast} from "react-toastify"
import useRooms from "contexts/rooms";

import {User, Bell, ChevronLeft} from "react-feather";

const Context = createContext();
const Default = {goBack: null, title: ""};

const BlankLayout = (props) => {

  const {children} = props;
  const [ctx, setCtx] = useState({...Default});
  const {refresh} = useRooms();
  const history = useHistory();
  const [ui] = useUI();

  useEffect(() => {
    if (!ui.user) history.push("/login");
  }, [ui]);

  return (
    <Context.Provider value={[ctx, setCtx]}>
      <div className="h-screen w-screen bg-gray-100 flex flex-col overflow-hidden">
        <div className="h-16 px-6 flex items-center justify-between" style={{background: "linear-gradient(89.94deg, #639BFA 0.04%, #762CF9 99.94%)"}}>
          <div className="flex-1 flex items-center" style={{gap: 15}}>
            {!ctx.goBack && <Phone color="white" size={34} className="cursor-pointer" onClick={() => history.push("/app/rooms")} />}
            {ctx.goBack && <ChevronLeft onClick={() => history.push(ctx.goBack)} color="white" size={34} className="cursor-pointer" />}
            {ctx.title && <h2 className="text-white text-2xl">{ctx.title}</h2>}
          </div>
          <div className="flex" style={{gap: 15}}>
            <Bell color="white" size={32} className="cursor-pointer" />
            <User color="white" size={32} className="cursor-pointer" onClick={() => history.push("/app/profile")}/>
          </div>
        </div>
        <div className="flex-1 overflow-auto">
          {children}
        </div>
      </div>
    </Context.Provider>
  )
}


const useLayout = () => useContext(Context);

const LayoutHOC = ({children}) => <Provider><BlankLayout>{children}</BlankLayout></Provider>

export default LayoutHOC;
export {useLayout};