
import BlankLayout from "layouts/Blank";
import DashboardLayout from "layouts/Dashboard";
import AudioprothesisteLayout from "layouts/Audioprothesiste";

import { faHome, faCoins, faUsers, faDiagramProject, faClock, faSquareCheck, faCalendar, faFile } from "@fortawesome/free-solid-svg-icons";

import Login from "pages/blank/login";
import Register from "pages/blank/register";
import Forgot from "pages/blank/forgotPassword";
import ProfilePage from "pages/dashboard/Profile";

import SuperadminHome from "./pages/dashboard/Superadmin_Home";
import SuperadminUsers from "./pages/dashboard/Superadmin_Users";
import SuperadminUser from "./pages/dashboard/Superadmin_User";
import SuperadminRooms from "./pages/dashboard/Superadmin_Rooms";
import SuperadminRoom from "./pages/dashboard/Superadmin_Room";

import MedecinHome from "./pages/dashboard/Medecin_Home";
import MedecinDemandes from "./pages/dashboard/Medecin_Demandes";
import MedecinDemande from "./pages/dashboard/Medecin_Demande";

import AudioprothesisteHome from "./pages/app/Audioprothesiste_Home";
import AudioprothesisteProfile from "./pages/app/Audioprothesiste_Profile";
import AudioprothesisteProfilePassword from "./pages/app/Profile/Password";
import AudioprothesisteProfileMe from "./pages/app/Profile/Me";
import AudioprothesisteDemande from "./pages/app/Demande/create";
import AudioprothesisteDemandeView from "./pages/app/AudioProthesiste_DemandeView";
import AudioprothesisteDemandeConfirm from "./pages/app/AudioProthesiste_DemandeConfirmation";
import AudioprothesisteRooms from "./pages/app/AudioProthesiste_Rooms.js";
import AudioprothesisteRoom from "./pages/app/AudioProthesiste_Room.js";
import AudioProthesisteCallAborted from "./pages/app/AudioProthesiste_CallAborted.js";

import Verif from "./pages/verif"

import useUI from "contexts/ui";

const useRouterConfig = () => {
  const [ui] = useUI();

  const layouts = [
    {name: "app", route: "/app",       component: AudioprothesisteLayout},
    ...(ui.user && ["superadmin", "medecin"].includes(ui.user.role.name) ? [{name: "dashboard",        route: "/dashboard", component: DashboardLayout}] : []),
    {name: "blank",            route: "",           component: BlankLayout},
  ];

  const routes = [
    {name: "login",    route: "",         layout: "blank",     component: Login,       hide: true},
    {name: "login",    route: "login",    layout: "blank",     component: Login,       hide: true},
    {name: "forgot",   route: "forgot",   layout: "blank",     component: Forgot,      hide: true},
    {name: "profile",  route: "profile",  layout: "dashboard", component: ProfilePage, hide: true},
    {name: "register", route: "register", layout: "blank",     component: Register,    hide: true},
    {name: "verif",    route: "verif",    layout: "blank",     component: Verif,       hide: true},
    
    //-------------------------- superadmin -------------------------------//
    ...(ui.user && ["superadmin", "medecin"].includes(ui.user.role.name) ? [
      {name: "home",  route: "",      layout: "dashboard", component: SuperadminHome, icon: faHome},
      {name: "rooms", route: "rooms", layout: "dashboard", component: SuperadminRooms, icon: faDiagramProject},
      {name: "room",  route: "rooms/:id", layout: "dashboard", component: SuperadminRoom, hide: true},
      ...(ui.user.role.name === "medecin" ? [] : ([
        {name: "users", route: "users", layout: "dashboard", component: SuperadminUsers, icon: faUsers},
        {name: "user", route: "users/:id", layout: "dashboard", component: SuperadminUser, hide: true},
      ])),
      {name: "demandes", route: "demandes", layout: "dashboard", component: MedecinDemandes, icon: faUsers},
      {name: "demande", route: "demandes/:id", layout: "dashboard", component: MedecinDemande, hide: true},
    ] : []),
    //-------------------------- audioprothesiste -------------------------------//
    ...(ui.user && ["audioprothesiste"].includes(ui.user.role.name) ? [
      {name: "home", route: "", layout: "app", component: AudioprothesisteHome, icon: faHome},
      {name: "profile", route: "profile", layout: "app", component: AudioprothesisteProfile, hide: true},
      {name: "password", route: "profile/password", layout: "app", component: AudioprothesisteProfilePassword, hide: true},
      {name: "me", route: "profile/me", layout: "app", component: AudioprothesisteProfileMe, hide: true},
      {name: "demande", route: "demande", layout: "app", component: AudioprothesisteDemande, hide: true},
      {name: "demande_confirm", route: "demandes/confirm", layout: "app", component: AudioprothesisteDemandeConfirm, hide: true},
      {name: "demande_view", route: "demandes/:id", layout: "app", component: AudioprothesisteDemandeView, hide: true},
      {name: "rooms", route: "rooms", layout: "app", component: AudioprothesisteRooms, icon: faDiagramProject},
      {name: "room", route: "rooms/:id", layout: "app", component: AudioprothesisteRoom, hide: true},
      {name: "aborted", route: "aborted", layout: "app", component: AudioProthesisteCallAborted, hide: true},
    ] : []),
  ];

  return {layouts, routes};
}

export default useRouterConfig;